<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2" flat>
        <v-row>
<v-col cols="12" md="4">
          <v-select
            label="Select Vendor"
            v-model="SelVendor"
            :items="Vendor"
            item-text="company_name"
            item-value="company_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
<v-col cols="12" md="4">
          <v-select
            label="Select Venue"
            v-model="Selvenue"
            :items="Venue"
            item-text="venue_name"
            item-value="venue_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
         <v-col cols="12" md="2">
          <v-btn medium color="primary" @click="btnsearch" class="ml-1">
            <v-icon left>mdi-reload</v-icon>Load Data</v-btn
          >
        </v-col>
        <v-col cols="12" md="2" v-if="desserts.length > 0">
          <export-excel :data="desserts" type="button">
            <v-btn color="success"
              ><v-icon left>mdi-microsoft-excel</v-icon>DOWNLOAD</v-btn
            >
          </export-excel>
        </v-col>

        </v-row>
    </v-card>

<v-card class="pa-1 mb-2" flat>
      <v-data-table
        v-model="TDselected"
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="application_id"
        loading-text="Loading... Please wait"
        style="border-radius: 0px"
      >

      <template v-slot:top>
          <v-card style="position: sticky; top: 0px; z-index: 5" flat>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                dense
                solo-inverted
                flat
                label="Search"
                hide-details
                style="max-width: 400px"
              ></v-text-field>
            </v-card-actions>
          </v-card>
      </template>
      <template v-slot:item.application_id="{ item }">
          <tr
           
          >
            <td style="text-align: left; width: 65px; padding-top: 6px">
              <img
                :src="$apiPath + item.picHeadShot"
                height="40px"
                width="40px"
                style="border-radius: 40px"
              />
            </td>

            <!-- <td>{{ item.fname }} {{item.lname}}</td>
          <td>{{ item.jobtype_name }}</td>
          <td>{{ item.print_text }}</td>
<td>{{ item.status_named }}</td>-->
          </tr>
        </template>
      </v-data-table>
</v-card>

  </div>
</template>

<script>
export default {
data(){
   return{
    search: "",
   SelVendor: "",
   Selvenue:"",
      Vendor: [],
      TDselected: [],
      desserts: [],
       headers: [
        {
          text: "AppID",
          align: "left",
          value: "app_id",
          class: "blue lighten-5 w-140",
        },

        {
          text: "Photo",
          align: "left",
          value: "application_id",
          class: "blue lighten-5 w-140",
        },

        {
          text: "Name",
          value: "fname",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Company",
          value: "company_name",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Designation",
          value: "jobtype_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "App Status",
          value: "status_named",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Card",
          value: "card_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Reason",
          value: "reason_name",
          align: "left",
          class: "blue lighten-5",
        },
         {
          text: "Pickup City",
          value: "pickup_city",
          align: "left",
          class: "blue lighten-5",
        },
      ],
   };

},
mounted(){
    this.$store.commit("setPageTitle", "Card pickup Report");
    this.$store.commit("setPageTitleIcon", "mdi-chart-box-plus-outline");
     this.bindCompany(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId
    );
    this.bindVenue(this.$store.state.eventInfo.EventId);
},
methods:{
bindCompany: async function (EventID, VenueID) {
      await this.$axios
        .get(
          "vendor/GetByEventId/" +
            EventID +
            "/" +
            VenueID +
            "/" +
            this.$store.state.eventInfo.EventAdminID
        )
        .then((res) => {
          if (res.data.result.length > 0) {
            this.Vendor = res.data.result;
            this.Vendor.splice(0, 0, { company_id: "0", company_name: "All" });
          }
        });
    },

bindVenue: async function (EventID) {
      await this.$axios
        .get(
          "media/mediavenue/" +
            EventID
            
        )
        .then((res) => {
          if (res.data.result.length > 0) {
            this.Venue = res.data.result;
            this.Venue.splice(0, 0, { venue_id: "0", venue_name: "All" });
          }
        });
    },
    
    binddata:async function(vendor_id,preferedCity){
      console.log(vendor_id,preferedCity);
      await this.$axios
        .get(
          "Report/Cardpickup/" +
            this.$store.state.eventInfo.EventId + "/" +
      this.$store.state.eventInfo.VenueId + "/" + vendor_id + "/" + preferedCity
            
        )
        .then((res) => {
          if (res.data.result.length > 0) {
            console.log(res.data.result);
            this.desserts=res.data.result;
          }
        });

    },
    btnsearch:function()
    {
        this.TDselected = [];
      this.desserts = [];
      this.binddata(this.SelVendor,this.Selvenue);
    },

    

},
}
</script>

<style>

</style>